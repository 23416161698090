<template>
  <div class="bg-white">
    <header class="relative overflow-hidden">
      <div class="pt-16 bg-gray-200 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
        <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
          <img
            :src="home.Header_Image"
            alt=""
            class="w-full h-full object-center object-cover"
            data-aos="fade-in"
          />
        </div>
        <div aria-hidden="true" class="absolute inset-0 bg-black opacity-90" />
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 static">
          <div>
            <h1
              class="text-6xl block font font-extrabold tracking-tight text-white sm:text-6xl z-50"
            >
              <span class="block">Welcome to </span>
              <span class="block">Direct Debs. </span>
            </h1>
            <p class="block text-md font-medium text-G">
              {{ home.Home_Sub_Title }}
            </p>
          </div>
          <div>
            <div class="mt-10">
              <!-- Decorative image grid -->
              <div
                aria-hidden="true"
                class="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
              >
                <div
                  class="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8"
                >
                  <div class="flex items-center space-x-6 lg:space-x-8">
                    <div
                      class="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8"
                    >
                      <div
                        class="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100"
                      >
                        <img
                          src="@/assets/G/5.jpeg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                      <div class="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="@/assets/D/12.jpg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8"
                    >
                      <div class="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="@/assets/D/26.jpeg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                      <div class="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="@/assets/P/12.jpeg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                      <div class="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="@/assets/G/12.jpeg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8"
                    >
                      <div class="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="@/assets/G/20.jpeg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                      <div class="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="@/assets/G/26.jpeg"
                          alt=""
                          class="w-full h-full object-center shadow-md object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <router-link
                to="/contact"
                class="inline-block text-center bg-G border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-yellow-700"
                data-aos="fade-up"
                >Contact Us</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </header>

    <main>
      <!-- Category section -->
      <section aria-labelledby="category-heading" class="bg-white">
        <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <div class="sm:flex sm:items-baseline sm:justify-between">
            <h1
              id="category-heading"
              class="text-4xl font-extrabold tracking-tight text-gray-900"
            >
              Our Services.
            </h1>
          </div>

          <div
            class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8"
          >
            <!-- DEBS -->
            <div
              class="group aspect-w-2 shadow-md aspect-h-1 rounded-lg overflow-hidden sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2"
              data-aos="fade-up"
            >
              <img
                :src="home.Debs_Background_Image"
                class="object-center object-cover group-hover:opacity-75"
              />
              <div
                aria-hidden="true"
                class="bg-gradient-to-b from-transparent to-black opacity-80"
              />
              <div class="p-6 flex items-end">
                <div>
                  <h3 class="text-4xl font-bold text-white">
                    <router-link to="/debs">
                      <span class="absolute inset-0" />
                      Debs
                    </router-link>
                  </h3>
                </div>
              </div>
            </div>
            <!-- PREDEBS -->
            <div
              class="group aspect-w-2 shadow-md aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full"
              data-aos="fade-up"
            >
              <img
                :src="home.Pre_Debs_Background_Image"
                class="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
              />
              <div
                aria-hidden="true"
                class="bg-gradient-to-b from-transparent to-black opacity-80 sm:absolute sm:inset-0"
              />
              <div class="p-6 flex items-end sm:absolute sm:inset-0">
                <div>
                  <h3 class="text-4xl font-bold text-white">
                    <router-link to="/predebs">
                      <span class="absolute inset-0" />
                      Pre-Debs
                    </router-link>
                  </h3>
                </div>
              </div>
            </div>
            <!-- GRADS -->
            <div
              class="group aspect-w-2 aspect-h-1 shadow-md rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full"
              data-aos="fade-up"
            >
              <img
                :src="home.Graduation_Background_Image"
                class="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
              />
              <div
                aria-hidden="true"
                class="bg-gradient-to-b from-transparent to-black opacity-80 sm:absolute sm:inset-0"
              />
              <div class="p-6 flex items-end sm:absolute sm:inset-0">
                <div>
                  <h3 class="text-4xl font-bold text-white">
                    <router-link to="/graduations">
                      <span class="absolute inset-0" />
                      Graduations
                    </router-link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- PARAGRAPH -->
      <section aria-labelledby="cause-heading">
        <div class="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
          <div class="absolute inset-0 overflow-hidden">
            <img
              :src="home.Paragraph_Background_Image"
              alt=""
              class="w-full h-full object-center object-cover"
            />
          </div>
          <div
            aria-hidden="true"
            class="absolute inset-0 bg-black bg-opacity-70"
          />
          <div
            class="relative max-w-3xl mx-auto flex flex-col items-center text-center"
          >
            <h2
              id="cause-heading"
              class="text-4xl font-extrabold tracking-tight text-white sm:text-4xl"
            >
              {{ home.Paragraph_Title }}
            </h2>
            <p class="block text-md font-medium text-white">
              {{ home.Paragraph }}
            </p>
          </div>
        </div>
      </section>

      <!-- Favorites section -->
      <section aria-labelledby="favorites-heading">
        <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <div class="sm:flex sm:items-baseline sm:justify-between">
            <h1
              id="favorites-heading"
              class="text-4xl font-extrabold tracking-tight text-gray-900"
            >
              Ireland's biggest venues.
            </h1>
          </div>

          <div
            class="mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:gap-x-8"
          >
            <div class="group relative">
              <div
                class="w-full h-96 rounded-lg overflow-hidden sm:h-auto sm:aspect-w-2 sm:aspect-h-3"
              >
                <img
                  :src="home.Venue_Image_1"
                  class="w-full h-full object-center object-cover shadow-md"
                  data-aos="fade-up"
                />
              </div>
            </div>
            <div class="group relative">
              <div
                class="w-full h-96 rounded-lg overflow-hidden sm:h-auto sm:aspect-w-2 sm:aspect-h-3"
              >
                <img
                  :src="home.Venue_Image_2"
                  class="w-full h-full object-center object-cover shadow-md"
                  data-aos="fade-up"
                />
              </div>
            </div>
            <div class="group relative">
              <div
                class="w-full h-96 rounded-lg overflow-hidden sm:h-auto sm:aspect-w-2 sm:aspect-h-3"
              >
                <img
                  :src="home.Venue_Image_3"
                  class="w-full h-full object-center object-cover shadow-md"
                  data-aos="fade-up"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- CTA section -->
      <section aria-labelledby="sale-heading">
        <div class="pt-32 overflow-hidden sm:pt-14">
          <div class="bg-G">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="relative pt-48 pb-16 sm:pb-24">
                <div>
                  <h2
                    id="sale-heading"
                    class="text-4xl font-extrabold tracking-tight text-white md:text-4xl"
                  >
                    Get your Debs, Pre-Debs
                    <br />
                    Or Grad Booked in now.
                  </h2>
                  <div class="mt-6 text-base">
                    <router-link
                      to="/contact"
                      class="block text-md font-medium text-white"
                      >Got questions? Read our FAQ's<span aria-hidden="true">
                        &rarr;</span
                      ></router-link
                    >
                  </div>
                </div>

                <div
                  class="absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0"
                >
                  <div
                    class="ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8"
                  >
                    <div
                      class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8"
                    >
                      <div class="flex-shrink-0">
                        <img
                          class="h-64 w-64 rounded-lg shadow-md object-cover md:h-72 md:w-72"
                          src="@/assets/G/9.jpeg"
                          alt=""
                        />
                      </div>

                      <div class="mt-6 flex-shrink-0 sm:mt-0">
                        <img
                          class="h-64 w-64 rounded-lg shadow-md object-cover md:h-72 md:w-72"
                          src="@/assets/G/17.jpeg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8"
                    >
                      <div class="flex-shrink-0">
                        <img
                          class="h-64 w-64 rounded-lg shadow-md object-cover md:h-72 md:w-72"
                          src="@/assets/G/22.jpeg"
                          alt=""
                        />
                      </div>

                      <div class="mt-6 flex-shrink-0 sm:mt-0">
                        <img
                          class="h-64 w-64 rounded-lg shadow-md object-cover md:h-72 md:w-72"
                          src="@/assets/G/15.jpeg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8"
                    >
                      <div class="flex-shrink-0">
                        <img
                          class="h-64 w-64 rounded-lg shadow-md object-cover md:h-72 md:w-72"
                          src="@/assets/P/12.jpeg"
                          alt=""
                        />
                      </div>

                      <div class="mt-6 flex-shrink-0 sm:mt-0">
                        <img
                          class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72 shadow-md"
                          src="@/assets/P/7.jpeg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// API SCRIPT
import axios from "axios";

export default {
  data() {
    return {
      home: {
        Header_Image: "",
        Home_Sub_Title: "",
        Debs_Background_Image: "",
        Pre_Debs_Background_Image: "",
        Graduation_Background_Image: "",
        Paragraph_Title: "",
        Paragraph: "",
        Paragraph_Background_Image: "",
        Venue_Image_1: "",
        Venue_Image_2: "",
        Venue_Image_3: "",
      },
    };
  },
  methods: {
    getHomePage() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/home?cv=1661660753&token=VJUdfvoIDyOFIfXpRVZ08Qtt&version=published"
        )
        .then((response) => {
          let homeContent = response.data["story"]["content"];
          __this.home = {
            Header_Image: homeContent["Header_Image"]["filename"],
            Home_Sub_Title: homeContent["Home_Sub_Title"],
            Debs_Background_Image:
              homeContent["Debs_Background_Image"]["filename"],
            Pre_Debs_Background_Image:
              homeContent["Pre_Debs_Background_Image"]["filename"],
            Graduation_Background_Image:
              homeContent["Graduation_Background_Image"]["filename"],
            Paragraph_Title: homeContent["Paragraph_Title"],
            Paragraph: homeContent["Paragraph"],
            Paragraph_Background_Image:
              homeContent["Paragraph_Background_Image"]["filename"],
            Venue_Image_1: homeContent["Venue_Image_1"]["filename"],
            Venue_Image_2: homeContent["Venue_Image_2"]["filename"],
            Venue_Image_3: homeContent["Venue_Image_3"]["filename"],
          };
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getHomePage();
  },
};
</script>

<script setup>
import ven1 from "../assets/D/14.jpg";
import ven2 from "../assets/G/14.jpeg";
import ven3 from "../assets/P/12.jpeg";

import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  MenuIcon,
  SearchIcon,
  ShoppingBagIcon,
  XIcon,
} from "@heroicons/vue/outline";

const favorites = [
  {
    id: 1,
    imageSrc: ven1,
  },
  {
    id: 2,
    imageSrc: ven2,
  },
  {
    id: 3,
    imageSrc: ven3,
  },
];

const open = ref(false);
</script>
